import {
  ActivatedRouteSnapshot, CanActivate,
  CanActivateChild,
  GuardResult, MaybeAsync, Router,
  RouterStateSnapshot
} from '@angular/router';
import {Injectable} from "@angular/core";
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard implements CanActivate, CanActivateChild {
  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ) {
    const requiredRoles = route.data.roles ?? []

    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true
    }

    return requiredRoles.some((role) => this.keycloakService.getUserRoles().includes(role))
  }

  constructor(protected override router: Router, protected readonly keycloakService: KeycloakService) {
    super(router, keycloakService);
  }

  override canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAccessAllowed(route, state);
  }

  canActivateChild(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
  ): MaybeAsync<GuardResult> {
    return this.isAccessAllowed(route, state);
  }
}
