import { Injectable } from '@angular/core';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from "keycloak-angular";
import { GaActionEnum, GoogleAnalyticsService } from "@hakimio/ngx-google-analytics";
import { Subject } from "rxjs";
import {Principal} from '@auth/principal.const';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private principal: Principal | null = null;
  initials: string | null = null;
  private loginSubject = new Subject<boolean>()
  loginChanged = this.loginSubject.asObservable()

  constructor(
    private keycloak: KeycloakService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    this.handleLoginStatus(this.keycloak.isLoggedIn())
    this.keycloak.keycloakEvents$.subscribe(evt => this.handleKeycloakEvent(evt))
  }

  async init(): Promise<void> {
    const loggedIn = this.keycloak.isLoggedIn();
    this.handleLoginStatus(loggedIn);
    this.keycloak.keycloakEvents$.subscribe(evt => this.handleKeycloakEvent(evt));
  }

  private handleLoginStatus(loggedIn: boolean) {
    // if (loggedIn)
    this.updatePrincipal()
    // else
    //   this.keycloak.login().then(() => this.updatePrincipal())
  }


  private updatePrincipal() {
    try {
      let keycloakInstance = this.keycloak.getKeycloakInstance()
      let token = keycloakInstance?.tokenParsed
      if (token == null) {
        this.clearPrincipal();
      } else {
        this.setPrincipal(token);
      }
    } catch (e) {
      console.log('Failed to load user details', e);
      this.clearPrincipal();
    }
  }

  public hasRole(role: string): boolean {
    if (this.principal == null) return false
    return this.principal.roles.find(r => r == role) != null;
  }

  public logout() {
    this.keycloak.logout().then()
  }

  login() {
    this.keycloak.login().then()
    this.googleAnalyticsService.event(GaActionEnum.LOGIN, {
      category: 'login_page',
      label: 'Login'
    })
  }

  getIdToken(): string | undefined {
    return this.keycloak.getKeycloakInstance().idToken
  }


  getToken(): string | undefined {
    return this.keycloak.getKeycloakInstance().token
  }

  public isLoggedIn(): boolean {
    return this.principal != null
  }

  public getPrincipal(): Principal | null {
    return this.principal
  }

  private clearPrincipal() {
    this.principal = null
    this.initials = null

    this.loginSubject.next(false)
  }

  private setPrincipal(token: any) {
    const id = token["sub"];
    const email = token["email"];
    const username = token["preferred_username"];
    const given_name = token["given_name"];
    const family_name = token["family_name"];
    const roles = token["realm_access"]["roles"];

    this.principal = {id, email, username, given_name, family_name, roles};

    this.initials = [this.principal.given_name.charAt(0).toUpperCase(), this.principal.family_name.charAt(0).toUpperCase()].join('')
    this.loginSubject.next(true)
  }

  private handleKeycloakEvent(evt: KeycloakEvent) {
    console.log("Keycloak Event: " + evt.type)
    switch (evt.type) {
      case KeycloakEventType.OnAuthLogout:
        this.clearPrincipal()
        break
      case KeycloakEventType.OnAuthRefreshError:
        this.clearPrincipal()
        break
      case KeycloakEventType.OnTokenExpired:
        this.handleOnTokenExpiredEvent()
        break
      case KeycloakEventType.OnAuthSuccess:
        this.updatePrincipal()
        break
      case KeycloakEventType.OnAuthRefreshSuccess:
        this.updatePrincipal()
        break
      case KeycloakEventType.OnActionUpdate:
        this.updatePrincipal()
        break
      case KeycloakEventType.OnReady:
        this.updatePrincipal()
        break
      case KeycloakEventType.OnAuthError:
        this.clearPrincipal()
        break
    }
  }


  private handleOnTokenExpiredEvent() {
    let loggedIn = this.isLoggedIn()
    if (loggedIn) {
      this.keycloak.updateToken(30).then()
    } else {
      this.clearPrincipal()
    }
  }
}
